import React from 'react';

import { Link } from 'gatsby';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import ArrowButton from '../../Atoms/ArrowButton/ArrowButton';
import {
  ArrowIconGoRight,
  HorizontalYellowLineShape,
} from '../../Atoms/Icons/Icons';
import useMedias from '../../hooks/useMedias';
import { Button } from '../../Molecules/Button/Button';
import SocialShare from '../../Molecules/SocialShare/SocialShare';
import { youtube_parser } from '../../Utils/Utils';

import './Carousel.scss';

let classNames = require('classnames');

const slideWidth = 200;

const createItem = (slides, position, idx) => {
  const item = {
    info: slides[idx].info,
    image: slides[idx].image,
    category: slides[idx].category || null,
  };

  const active_slide = slides.length < 3 ? 1 : 3;

  switch (position) {
    case active_slide - 1:
      item.styles = {
        transform: `translateX(${position * slideWidth}px)`,
        width: '740px',
        height: '100%',
      };
      item.class_name = 'active';
      break;
    case active_slide + 1:
    case active_slide + 2:
    case active_slide:
      item.styles = {
        transform: `translateX(${position * slideWidth + 540}px)`,
      };
      item.class_name = '';
      break;

    case active_slide + 3:
      item.styles = {
        transform: `translateX(${position * slideWidth + 540}px)`,
        opacity: 0,
      };
      item.class_name = '';
      break;
    default:
      item.styles = {
        transform: `translateX(${position * slideWidth}px)`,
        opacity: 0,
      };
      item.class_name = '';
      break;
  }

  return item;
};

const CarouselSlideItem = ({ slides, pos, idx, sliderMode, openVideo }) => {
  const { processUrl } = useMedias();

  const item = createItem(slides, pos, idx);
  sliderMode = sliderMode || '';

  const showVideo = (e) => {
    e.preventDefault();
    openVideo(youtube_parser(e.target.dataset.video));
  };

  return (
    <li
      className={classNames('carousel__slide-item', item.class_name)}
      style={item.styles}
    >
      <div className="carousel__slide-item-img-link">
        <div className="visuel">
          <img
            className="fluid_"
            src={processUrl(item.image.uri.url)}
            alt={item.info.text}
            style={{ height: '100%', width: '100%', objectFit: 'cover' }}
          />
        </div>
      </div>
      <div
        className={classNames('info', 'info_style_' + sliderMode, {
          no_btn:
            sliderMode != 'articles' &&
            (!item.info.link_label || !item.info.link),
        })}
      >
        <p className="title">
          {item.info.link ? (
            <>
              {item.info.link.indexOf('.pdf') > 0 ? (
                <a href={item.info.link} target='_blank' rel="noreferrer">
                  {item.info.text}{' '}
                </a>
              ) : (
                item.info.link_label ? <a href={item.info.link}> {item.info.text}{' '} </a> : <a href="#" data-video={item.info.link} onClick={showVideo}> {item.info.text}{' '} </a>
              )}
            </>
          ) : (
            item.info.text
          )}
        </p>
        {item.info.description && <p>{item.info.description}</p>}
        {sliderMode != 'articles' && item.info.link_label && item.info.link && (
          <Button
            primary={true}
            label={item.info.link_label}
            link={
              item.info.link +
              (item.info.anchor !== undefined ? item.info.anchor : '')
            }
            arial-label={item.info.link_label + ' - ' + item.info.text}
          />
        )}
      </div>
    </li>
  );
};

const Carousel = ({ slides, sliderMode, textColor, openVideo }) => {
  const intl = useIntl();
  const _slides = slides;
  const keys = Array.from(Array(_slides.length).keys());
  const [items, setItems] = React.useState(keys);
  const bigLength = items.length;
  const prevClick = (jump = 1) => {
    setItems((prev) => {
      return prev.map((_, i) => prev[(i + jump) % bigLength]);
    });
  };

  const nextClick = (jump = 1) => {
    setItems((prev) => {
      return prev.map((_, i) => prev[(i - jump + bigLength) % bigLength]);
    });
  };

  return (

    <div
      className={classNames('carousel__wrap', 'slider_mode_' + sliderMode, {
        no_slider: bigLength < 3,
      })}
    >

      <div className="carousel__inner">
        <div className="carousel__container">
          <ul
            className="carousel__slide-list"
            style={{
              width: 740 + (_slides.length - 1) * slideWidth + 10 * 20 + 'px',
            }}
          >
            {items.map((pos, i) => (
              <CarouselSlideItem
                slides={_slides}
                key={i}
                idx={i}
                pos={pos}
                sliderMode={sliderMode}
                openVideo={openVideo}
              />
            ))}
          </ul>
        </div>

        <ArrowButton
          direction="prev"
          callback={prevClick}
          text={intl.formatMessage({
            id: 'carrousel.prev',
          })}
          textColor={textColor}
        />
        <ArrowButton
          direction="next"
          callback={nextClick}
          text={intl.formatMessage({
            id: 'carrousel.next',
          })}
          textColor={textColor}
        />
      </div>
    </div>
  );
};

export default Carousel;
