import React, { useState, useEffect, useRef, useCallback } from 'react';

import { FormattedMessage, useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n/src';
import { CloseIcon } from '../../Atoms/Icons/Icons';
import VideoYoutube from '../../components/Video/VideoYoutube';

import './PopinVideo.scss';

const PopinVideo = ({ videoId, closePopinVideo, stopVideo }) => {
  const popinRef = useRef();
  const intl = useIntl();
  //const video = document.body.querySelector('video');
  const [localVideoId, setLocalVideoId] = useState('');

  useEffect(() => {
    if (popinRef.current) {
      popinRef.current.addEventListener('keydown', onKeyPressed);
      return () => { popinRef.current ? popinRef.current.removeEventListener('keydown', onKeyPressed) : null };
    }
  }, []);

  useEffect(() => {
    setLocalVideoId(videoId);
  }, [videoId]);

  const onKeyPressed = (e) => {
    if (e.keyCode === 9 && e.target.classList.contains('btn_close')) {
      e.preventDefault();
      popinRef.current.querySelectorAll('.btn')[0].focus();
    }
  };

  const onHideVideo = (e) => {
    e.preventDefault();
    closePopinVideo();
  };

  return (
    <div className="popin_video " ref={popinRef} role="dialog" aria-labelledby="accessmodaltitle" id="popin_video">
      <div className="popin_content">
        <div className="content_wrapper">

          <VideoYoutube
            /* cover={processUrl(
                        getImage(
                          imagesArray,
                          block?.relationships?.field_image
                            ?.drupal_internal__mid
                        )?.uri?.url
                      )}*/
            videoID={localVideoId}
            videoTitle={'Video TEst'}
            stop={stopVideo}
            obj={{
              height: '390',
              width: '640',
              playerVars: {
                autoplay: 0,
                controls: 0,
                disablekb: 1,
                rel: 0,
                showinfo: 0,
              },
            }}
          />

        </div>
        <div className="div_btn_close">
          <a href="#" aria-label={intl.formatMessage({ id: 'ariaLabel.close_modal' })} className="btn_close" onClick={(e) => onHideVideo(e)}>
            <CloseIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PopinVideo;
