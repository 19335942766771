import React, { useEffect, useState } from 'react';
import useMedias from '../../../../hooks/useMedias';
import DocumentView from '../../../../Molecules/DocumentView/DocumentView';
import ShapeBackground from '../../../../Molecules/ShapeBackground/ShapeBackground';
import TitleBlock from '../../../../Molecules/TitleBlock/TitleBlock';
import TitleSection from '../../../../Molecules/TitleSection/TitleSection';

var classNames = require('classnames');

const DocumentSection = ({
  documentDetails,
  titleSection = false,
  hasBackground,
  sectionClass,
}) => {
  const { processUrl } = useMedias();

  const [dataArr, setDataArr] = useState([]);

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'Ko', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + sizes[i];
  };

  useEffect(() => {
    let tempDataArr = [];
    documentDetails?.content[0]?.files?.forEach((file) => {
      tempDataArr.push({
        name: file?.description,
        size: `(.pdf ${formatBytes(file?.filesize)})`,
        link: processUrl(file?.uri?.url),
        target: '_blank',
      });
    });

    setDataArr(tempDataArr);
  }, [documentDetails]);

  return (
    <>
      {titleSection &&
        documentDetails.title != undefined &&
        documentDetails.title != null ? (
        <section
          className={classNames(
            'section_content',
            'section_documents',
            sectionClass
          )}
        >
          {hasBackground ? (
            <ShapeBackground color="catskill_white" bottomInfinite={true}>
              <div className="wrapper_page_xs">
                <TitleSection title={documentDetails.title} type="arrow" />
              </div>
              <div className="wrapper_page">
                <div className="section_inner_content">
                  {dataArr.length > 0 && <DocumentView data={dataArr} />}
                </div>
              </div>
            </ShapeBackground>
          ) : (
            <>
              <div className="wrapper_page_xs">
                <TitleSection title={documentDetails.title} type="arrow" />
              </div>
              <div className="wrapper_page">
                <div className="section_inner_content">
                  {dataArr.length > 0 && <DocumentView data={dataArr} />}
                </div>
              </div>
            </>
          )}
        </section>
      ) : (
        <div className={classNames('reports_container', 'block_h3_content')}>
          <div className="wrapper_page">
            {documentDetails?.title &&
              documentDetails?.title != 'undefined' && (
                <TitleBlock
                  headingTag="h3"
                  title={documentDetails?.title}
                  color="color_dark_bleu"
                />
              )}
            <div className="section_inner_content">
              {dataArr.length > 0 && <DocumentView data={dataArr} />}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DocumentSection;
