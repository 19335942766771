import * as React from 'react';

import { useIntl } from '../../../plugins/publicis-gatsby-plugin-i18n';
import { IconDownload, IconPreview, IconDownloadZip } from '../../Atoms/Icons/Icons';

import './DocumentView.scss';

const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

const getSingleDocument = ({
  name,
  size,
  link,
  xhtml_link,
  target = '_blank',
  showName = true,
}) => {
  const intl = useIntl();
  return (
    <div className="document_view_btns">
      {(name || size) && (
        <div className="details">
          <p>{showName && name}</p>
          <span>{size}</span>
        </div>
      )}
      <div className="icons">
        <a
          href={link}
          target={target}
          aria-label={
            intl.formatMessage({ id: 'common.open' }) +
            ' PDF, ' +
            name +
            ', ' +
            size +
            ', ' +
            intl.formatMessage({ id: 'ariaLabel.nouvel_onglet' })
          }
        >
          <IconPreview />
        </a>
        <a
          href={link}
          target={target}
          download={name ? name : 'document'}
          aria-label={
            intl.formatMessage({ id: 'ariaLabel.download' }) +
            ' PDF, ' +
            name +
            ', ' +
            size +
            ', ' +
            intl.formatMessage({ id: 'ariaLabel.nouvel_onglet' })
          }
        >
          <IconDownload />
        </a>
        {xhtml_link ?
          <a
            href={xhtml_link}
            download={name ? name : 'document'}
            aria-label={intl.formatMessage({ id: 'ariaLabel.download' }) + ' XHTML ZIP '}
          >
            <IconDownloadZip />
          </a>
          : null
        }
      </div>
    </div>
  );
};

const DocumentView = ({ data }) => {
  return (
    <div className="documents_container">
      {data.map((document, index) => (
        <div className="document_view_line" key={generateKey(index)}>
          {getSingleDocument(document)}
        </div>
      ))}
    </div>
  );
};

export default DocumentView;
