import React, { useEffect, useState } from 'react';

import { graphql } from 'gatsby';

import AudioComponent from '../../../components/AudioComponent/AudioComponent';
import { Button } from '../../../Molecules/Button/Button';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import Carousel from '../../../components/Carousel/Carousel';
import DocumentSection from '../../../components/ContentType/Section/DocumentSection/DocumentSection';
import {
  getBreadCrumb,
  getReOrderedCarouselSlides,
  removeHtmlTag,
  youtube_parser,
} from '../../../Utils/Utils';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import Layout from '../../../Layout';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import VideoSection from '../../../components/ContentType/Section/VideoSection/VideoSection';
import useMedias from '../../../hooks/useMedias';
import PopinVideo from '../../../components/PopinVideo/PopinVideo';

import './styles.scss';
let classNames = require('classnames');

const EngagementEnjeux = ({ data }) => {
  const intl = useIntl();
  const { processUrl, getImage, getDocument } = useMedias();

  const imagesArray = data?.allImages?.edges;
  const pageData = data?.pageData?.edges[0].node;
  const allMenu = data.allMenu.edges;
  const breadCrumb = getBreadCrumb(allMenu, pageData.path?.alias);
  const documentsArray = data.allDocuments.edges;
  const metaTags =  pageData.metatag;
  let metaTitle = '';
  let metaDesc = '';
  
   metaTags.forEach((meta) => {
      if(meta.attributes.name === 'title'){
           metaTitle = meta.attributes.content;
      }
      if(meta.attributes.name === 'description'){
           metaDesc = meta.attributes.content;
      }
    });

  const [innerNavList, setInnerNavList] = useState([]);
  const [allText, setAllText] = useState('');
  const [videoId, setVideoId] = useState('');
  const [stopVideo, setStopVideo] = useState(false);

  let sliders = [];
  let sectionArr = [];
  let leftRightBlocks = [];
  let sectionCount = 0;
  let globalText = '';

  const slider_settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '20px',
    slidesToShow: 1, //no slider on desktop
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    setInnerNavList(sectionArr);
    setAllText(
      removeHtmlTag(
        `${pageData.title}.${pageData.field_sous_titre !== null &&
          pageData.field_sous_titre !== undefined
          ? pageData.field_sous_titre
          : ''
        }.${globalText}`
      )
    );
  }, []);

  const loadVideo = (val) => {
    setVideoId(val);
    setStopVideo(false);
    document.body.classList.add('show_video_popin');
  }

  const closePopinVideo = () => {
    document.body.classList.remove('show_video_popin');
    setStopVideo(true);
    if (document.querySelector('a.accessibilite')) {
      document.querySelector('a.accessibilite').focus();
    }
  }

  const mobileClickHandler = (e) => {
    e.preventDefault();
    loadVideo(youtube_parser(e.target.dataset.video))
  }

  return (
    <>
      <PopinVideo videoId={videoId} closePopinVideo={closePopinVideo} stopVideo={stopVideo} />
      <Layout>

        <Seo
        title={pageData?.field_metatag?.title ? pageData?.field_metatag?.title : metaTitle}
        description={pageData?.field_metatag?.description ? pageData?.field_metatag?.description : metaDesc}
        />

        <div
          className={classNames(
            'page_template',
            'page_engagement_enjeux',
            !pageData?.field_padding && 'zero_bottom_padding'
          )}
        >


          <ScrollPageNav data={innerNavList} />
          <PageBanner
            visuel={getImage(
              imagesArray,
              pageData?.relationships?.field_image?.drupal_internal__mid
            )}
            scroll={true}
          >
            <div className="wrapper_page">
              <Breadcrumb
                page_banner={true}
                data={{
                  parentPage: {
                    title: breadCrumb?.parent?.name,
                    url: breadCrumb?.parent?.link,
                  },
                  currentPage: {
                    title: breadCrumb?.current?.name,
                    url: breadCrumb?.current?.link,
                  },
                  locale: pageData.langcode,
                }}
              />
              <div
                className={classNames({
                  title_audio:
                    allText &&
                    pageData.field_version_audio &&
                    allText.length > 30,
                })}
              >
                <TitlePage
                  color="color_white"
                  title={pageData.title}
                  description={pageData.field_sous_titre}
                />
                {allText && pageData.field_version_audio && allText.length > 30 && (
                  <AudioComponent
                    text={intl.formatMessage({
                      id: 'detailsActivities.button.listen.label',
                    })}
                    textToRead={allText}
                    color="color_white"
                  />
                )}
              </div>
            </div>
          </PageBanner>
          {pageData.relationships?.field_blocs?.map((block, i) => {
            if (block.field_title?.processed && pageData.field_progress) {
              sectionCount++;
              sectionArr.push({
                title: block.field_title?.processed,
                section: 'scroll_to_' + sectionCount,
              });
            }
            switch (block.__typename) {
              case 'block_content__block_texte':
                leftRightBlocks = [];
                globalText += `${block.field_title?.processed != undefined &&
                  block.field_title?.processed != null
                  ? block.field_title?.processed
                  : ''
                  }.${block.field_subtitle?.processed != undefined &&
                    block.field_subtitle?.processed != null
                    ? block.field_subtitle?.processed
                    : ''
                  }.${block.body?.processed}`;
                block.relationships?.field_section?.forEach((item) => {
                  globalText += `${item.relationships?.field_bloc_texte[0]?.field_title?.processed}.${item.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed}`;
                  leftRightBlocks.push({
                    text: item.relationships?.field_bloc_texte[0]
                      ?.field_bloc_text_1?.processed,
                    title:
                      item.relationships?.field_bloc_texte[0]?.field_title
                        ?.processed,
                    visuel: getImage(
                      imagesArray,
                      item?.relationships?.field_image?.drupal_internal__mid
                    ),
                    arrowColor:
                      item.relationships?.field_bloc_texte[0]?.field_theme_title,
                    field_no_padding: item.field_no_padding,
                    field_alignment: item.field_alignment,
                  });
                });
                return (
                  <div key={i}>
                    <IntroPage
                      customClass={'scroll_to_' + sectionCount}
                      title={block.field_title?.processed}
                      subtitle={block.field_subtitle?.processed}
                      visuel={getImage(
                        imagesArray,
                        block?.relationships?.field_image?.drupal_internal__mid
                      )}
                      text={block.body?.processed}
                      size="border" //wrapper
                    />
                    {leftRightBlocks.length > 0 && (
                      <section
                        className={classNames(
                          'section_content',
                          'block_1_section'
                        )}
                      >
                        <ShapeBackground
                          top={
                            block.field_backgroud_color === 'ice'
                              ? 'right'
                              : block.field_border_top && 'left'
                          }
                          color={
                            block.field_backgroud_color === 'ice'
                              ? 'catskill_white'
                              : block.field_backgroud_color === 'navy'
                                ? 'dark_bleu'
                                : 'white'
                          }
                          bottomInfinite={block.field_infinite_border}
                          bottom={block.field_border_bottom_display}
                        >
                          <div className="wrapper_page_xs">
                            {leftRightBlocks.map((myBlock, index) => (
                              <TextVisuelTwoCol
                                key={index}
                                title={myBlock.title}
                                colorTitle={
                                  myBlock.arrowColor === 'theme_1'
                                    ? 'color_yellow'
                                    : 'color_dark_bleu'
                                }
                                visuel={myBlock.visuel}
                                extremeLeft={
                                  myBlock?.field_no_padding &&
                                    myBlock?.field_alignment === 'left'
                                    ? true
                                    : false
                                }
                                extremeRight={
                                  myBlock?.field_no_padding &&
                                    myBlock?.field_alignment === 'right'
                                    ? true
                                    : false
                                }
                                orderInverse={
                                  myBlock?.field_alignment === 'right'
                                    ? true
                                    : false
                                }
                                alignTop={true}
                                text={myBlock.text}
                                colorDescription="color_black"
                              />
                            ))}
                          </div>
                        </ShapeBackground>
                      </section>
                    )}
                  </div>
                );

              case 'block_content__block_slider_innovation':
                sliders = [];
                globalText += `${block.field_title?.processed}.${block.body?.processed}`;
                block.relationships?.field_slides?.forEach((item) => {
                  sliders.push({
                    image: getImage(
                      imagesArray,
                      item?.relationships?.field_image?.drupal_internal__mid
                    ),
                    info: {
                      text: item.field_title?.processed,
                      link: block.field_link?.url,
                      link_label: block.field_link?.title,
                      anchor:
                        '?anchor=section-' +
                        item?.relationships?.field_anchor?.drupal_internal__tid,
                    },
                  });
                });
                sliders.push(...sliders);

                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_1_section',
                      'scroll_to_' + sectionCount
                    )}
                    key={i}
                  >
                    <ShapeBackground
                      top={block.field_border_top && 'left'}
                      color={
                        block.field_backgroud_color === 'ice'
                          ? 'catskill_white'
                          : block.field_backgroud_color === 'navy'
                            ? 'dark_bleu'
                            : 'white'
                      }
                      bottomInfinite={block.field_infinite_border}
                      bottom={block.field_border_bottom_display}
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={block.field_title?.processed}
                          type="arrow"
                          h2color={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'white'
                                : ''
                          }
                          h4color={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'white'
                                : ''
                          }
                          className={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'color_white'
                                : ''
                          }
                        />
                        <div
                          className={
                            block.field_backgroud_color === 'ice'
                              ? ''
                              : block.field_backgroud_color === 'navy'
                                ? 'color_white'
                                : ''
                          }
                          dangerouslySetInnerHTML={{
                            __html: block.body?.processed,
                          }}
                        />
                      </div>
                      <div className={classNames('only_mobile_tablet')}>
                        <SliderComponent settings={slider_settings}>
                          {sliders.map((slide, j) => (
                            <div key={j}>
                              {slide?.image && (
                                <img
                                  src={processUrl(slide.image.uri.url)}
                                  alt={`slide-${i}-${j}`}
                                  style={{
                                    width: '100%',
                                    height: '123vw',
                                    maxHeight: '510px',
                                    objectFit: 'cover',
                                  }}
                                />
                              )}
                              <div className="info_title_description">
                                <p>{slide.info.text}</p>
                                {slide.info.link && (
                                  <Button
                                    primary={true}
                                    label={slide.info.link_label}
                                    link={slide.info.link}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                      <div className="wrapper_page_xs">
                        <div className={classNames('only_desktop')}>
                          <Carousel
                            slides={getReOrderedCarouselSlides(sliders)}
                            sliderMode=""
                            textColor={
                              block.field_backgroud_color === 'ice'
                                ? ''
                                : block.field_backgroud_color === 'navy'
                                  ? 'color_white'
                                  : ''
                            }
                          />
                        </div>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_slider_nos_activites':
                let slides = [];
                globalText += `${block.field_title?.processed}.${block.body?.processed}`;
                block.relationships?.field_nos_activites?.forEach((item) => {

                  slides.push({
                    image: getImage(
                      imagesArray,
                      item?.relationships?.field_image?.drupal_internal__mid
                    ),
                    info: {
                      text: item.title,
                      description: item.body?.summary,
                      link: block.field_link_title ? '/' + item.path?.langcode + item.path?.alias : item.field_link?.url,
                      videoUrl: item.field_link?.url,
                      link_label: block.field_link_title
                    },
                  });
                });

                return (
                  <section
                    className={classNames(
                      'section_content',
                      'block_2_section',
                      'scroll_to_' + sectionCount
                    )}
                  >

                    <ShapeBackground
                      top={
                        block.field_border_top &&
                          block.field_position_bordure_haut === 'gauche'
                          ? 'left'
                          : block.field_border_top &&
                          block.field_position_bordure_haut != null &&
                          'right'
                      }
                      color={
                        block.field_backgroud_color === 'ice'
                          ? 'catskill_white'
                          : block.field_backgroud_color === 'navy'
                            ? 'dark_bleu'
                            : 'white'
                      }
                      bottomInfinite={block.field_infinite_border}
                      bottom={block.field_border_bottom_display}
                    >
                      <div className={classNames('wrapper_page_xs')}>
                        <TitleSection
                          title={block.field_title?.processed}
                          subtitle={block.field_subtitle?.processed}
                          type="arrow"
                          h2color={block.field_backgroud_color === 'navy' ? 'white' : 'bleu'}
                          h4color={block.field_backgroud_color === 'navy' ? 'white' : 'bleu'}
                        />
                      </div>
                      <div
                        className={classNames('wrapper_page_xs', 'only_desktop')}
                      >
                        <Carousel
                          slides={getReOrderedCarouselSlides(slides)}
                          sliderMode="title_description"
                          openVideo={loadVideo}
                        />
                      </div>
                      <div className={classNames('only_mobile_tablet')}>
                        <SliderComponent settings={slider_settings}>
                          {slides.map((slide, index) => (
                            <div key={index}>
                              <div className="only_mobile">
                                <div className="image-wrapper">
                                  <img
                                    src={processUrl(slide.image.uri.url)}
                                    alt={`slide-${index}`}
                                    style={{
                                      width: '100%',
                                      height: '123vw',
                                      maxHeight: '510px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="only_tablet">
                                <img
                                  src={processUrl(slide.image.uri.url)}
                                  alt={`slide-${index}`}
                                  style={{ objectFit: 'cover' }}
                                />
                              </div>
                              <div className="info_title_description">
                                <p data-video={slide.info.link} onClick={slide.info.link ? mobileClickHandler : null}>{slide.info.text}</p>
                                {slide.info.link_label && slide.info.link && (
                                  <Button
                                    primary={true}
                                    label={slide.info.link_label}
                                    link={slide.info.link}
                                  //videoUrl={slide.info.link}
                                  //onClick={mobileClickHandler}
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                    </ShapeBackground>
                  </section>
                );

              case 'block_content__block_chiffres_clefs':
                // eslint-disable-next-line no-case-declarations
                const dataArr = [];
                block.relationships?.field_values?.forEach((item) => {
                  dataArr.push({
                    number: item.field_chiffre,
                    title: item.field_legend?.processed,
                  });
                });
                return (
                  <KeyNumbers
                    key={i}
                    title={block.field_title?.processed}
                    subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                    sectionName={'scroll_to_' + sectionCount}
                    textColor="white"
                    NoPadding={true}
                    backgroundImg={getImage(
                      imagesArray,
                      block.relationships?.field_image?.drupal_internal__mid
                    )}
                    datas={dataArr}
                    locale={block.langcode}
                  />
                );

              case 'block_content__block_video':
                const videoContent = {
                  content: [
                    {
                      type: 'video',
                      link: block.field_link?.uri,
                      title: removeHtmlTag(block.field_title?.processed),
                      image: getImage(
                        imagesArray,
                        block.relationships?.field_image?.drupal_internal__mid
                      ),
                    },
                  ],
                };
                return <VideoSection videoDetails={videoContent}></VideoSection>;

              case 'block_content__block_document':
                let tempFileArr = [];
                block.relationships?.field_file.forEach((file) => {
                  tempFileArr.push(
                    getDocument(documentsArray, file?.drupal_internal__mid)
                  );
                });
                const documentContent = {
                  year: block.node?.relationships?.field_year?.name,
                  title: removeHtmlTag(block?.field_title?.processed),
                  category: block?.field_category,
                  content: [
                    {
                      type: 'document',
                      files: tempFileArr,
                    },
                  ],
                };

                return (
                  <>
                    <DocumentSection
                      documentDetails={documentContent}
                    ></DocumentSection>
                  </>
                );
            }
          })}
        </div>

      </Layout>
    </>
  );
};

export const query = graphql`
  query defaultTemplateQuery(
    $locale: String!
    $slug: String!
    $imagesID: [Int!]
    $documentsID: [Int!]
  ) {
    pageData: allNodePage(
      filter: { langcode: { eq: $locale }, path: { alias: { eq: $slug } } }
    ) {
      edges {
        node {
          title
          langcode
          field_type
          field_padding
          field_sous_titre
          field_version_audio
          field_progress
          field_metatag {
            description
            title
          }
          metatag {
            attributes {
              content
              name
            }
          }
          path {
            alias
            langcode
          }
          relationships {
            field_image {
              drupal_internal__mid
            }
            field_blocs {
              __typename
              ... on Node {
                ... on block_content__basic {
                  id
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                  }
                  field_link {
                    title
                    url
                  }
                  field_no_padding
                  field_alignment
                  field_subtitle {
                    processed
                  }
                  field_title {
                    processed
                  }
                  field_alignment
                }
                ... on block_content__block_slider_innovation {
                  id
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  body {
                    processed
                  }
                  relationships {
                    field_slides {
                      field_title {
                        processed
                      }
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                        field_anchor {
                          drupal_internal__tid
                        }
                      }
                    }
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom_display
                  field_link {
                    title
                    url
                  }
                }
                ... on block_content__block_texte {
                  id
                  field_title {
                    processed
                  }
                  body {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_backgroud_color
                  field_infinite_border
                  field_border_top
                  field_border_bottom_display
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_section {
                      field_no_padding
                      field_alignment
                      relationships {
                        field_image {
                          drupal_internal__mid
                        }
                        field_bloc_texte {
                          field_theme_title
                          field_title {
                            processed
                          }
                          field_bloc_text_1 {
                            processed
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_slider_nos_activites {
                  field_title {
                    processed
                  }
                  field_subtitle {
                    processed
                  }
                  field_link_title
                  field_border_top
                  field_infinite_border
                  field_border_bottom
                  field_backgroud_color
                  field_position_bordure_haut
                  relationships {
                    field_nos_activites {
                      __typename
                      ... on Node {
                        ... on node__realisations {
                          title
                          body {
                            summary
                          }
                          field_link {
                            url
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                            }
                          }
                          path {
                            alias
                            langcode
                          }
                        }
                        ... on node__activites {
                          title
                          body {
                            summary
                          }
                          path {
                            alias
                            langcode
                          }
                          relationships {
                            field_image {
                              drupal_internal__mid
                            }
                          }
                          field_text_2 {
                            processed
                          }
                        }
                      }
                    }
                  }
                }
                ... on block_content__block_chiffres_clefs {
                  body {
                    processed
                  }
                  field_title {
                    processed
                  }
                  langcode
                  relationships {
                    field_image {
                      drupal_internal__mid
                    }
                    field_values {
                      field_legend {
                        processed
                      }
                      field_chiffre
                    }
                  }
                }

                ... on block_content__block_document {
                  field_title {
                    processed
                  }
                  field_background
                  relationships {
                    field_file {
                      drupal_internal__mid
                    }
                  }
                }
              }
            }
          }
          body {
            processed
          }
        }
      }
    }

    allImages: allMediaImage(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $imagesID }
      }
    ) {
      edges {
        node {
          drupal_internal__mid
          relationships {
            field_media_image {
              uri {
                url
              }
            }
          }
        }
      }
    }
    allMenu: allMenuLinkContentMenuLinkContent(
      filter: { langcode: { eq: $locale } }
    ) {
      edges {
        node {
          drupal_parent_menu_item
          drupal_id
          langcode
          link {
            url
          }
          menu_name
          title
        }
      }
    }
    allDocuments: allMediaDocument(
      filter: {
        langcode: { eq: "fr" }
        drupal_internal__mid: { in: $documentsID }
      }
    ) {
      edges {
        node {
          langcode
          field_media_document {
            description
          }
          relationships {
            field_media_document {
              filesize
              filename
              filemime
              uri {
                url
              }
            }
          }
          drupal_internal__mid
        }
      }
    }
  }
`;

export default EngagementEnjeux;
